const buttons = document.querySelectorAll('.three-cards-btn');

for (const button of buttons) {
  button.addEventListener('click', btnSend);
}

function btnSend(event) {
  const idBtn = event.target.id;
  console.log(idBtn);

  let text;

  switch (idBtn) {
    case 'btn-one':
      text = 'Hello,I want to order rate Poland 9 months season permit.';
      break;
    case 'btn-two':
      text = 'Hello,I want to order rate Poland Poland 1-3 years Voevoda.';
      break;
    case 'btn-three':
      text = 'Hello,I want to order rate Serbia 6 months.';
      break;
    case 'btn-four':
      text = 'Hello,I want to order rate Hungary 1 year.';
      break;
    case 'btn-five':
      text = 'Hello,I want to order rate Lithuania 2 years residence permit.';
      break;
    case 'btn-six':
      text = 'Hello,I want to order rate Czech Republic 3 months.';
      break;
    case 'tn-seven':
      text =
        'Hello,I want to order rate Czech Republic 6 months season permit.';
      break;
    case 'btn-eight':
      text =
        'Hello,I want to order rate Czech Republic 9 months season permit.';
      break;
    case 'btn-nine':
      text =
        'Hello,I want to order rate Czech Republic 2 years residence permit.';
      break;
    case 'btn-ten':
      text = 'Hello,I want to order rate Croatia 1 year permit .';
      break;
    case 'btn-eleven':
      text = 'Hello,I want to order rate Romania 1 year permit.';
      break;

    default:
      break;
  }

  sendMessageWhatsApp(text);
}

function sendMessageWhatsApp(value) {
  const phoneNumber = '+37064823762';
  const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
    value
  )}`;
  window.open(url, '_blank');
}
